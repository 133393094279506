import {
  DataZoomComponentOption,
  LegendComponentOption,
  MarkAreaComponentOption,
  MarkLineComponentOption,
  TitleComponentOption,
  TooltipComponentOption,
  XAXisComponentOption,
  YAXisComponentOption,
} from 'echarts';
import { COLORS } from './styles';

/**
 * constant for echart options
 * @constant TITLE
 */
export const TITLE: TitleComponentOption = {
  left: '10%',
  textStyle: {
    color: COLORS.textColor,
  },
};
/**
 * constant for echart options
 * @constant YAXIS
 */
export const YAXIS: YAXisComponentOption = {
  type: 'value',
  axisLine: {
    show: true,
    lineStyle: {
      color: COLORS.lineColor,
    },
  },
  axisLabel: {
    color: COLORS.axisColor,
  },
  nameTextStyle: {
    color: COLORS.textColor,
  },
  alignTicks: true,
};

/**
 * constant for echart options
 * @constant XAXIS
 */
export const XAXIS: XAXisComponentOption = {
  type: 'value',
  splitLine: {
    show: true,
  },
  axisTick: {
    show: false,
    lineStyle: {
      color: COLORS.lineColor,
    },
  },
  axisLabel: {
    color: COLORS.axisColor,
  },
  nameTextStyle: {
    color: COLORS.textColor,
    fontWeight: 'bold',
    align: 'left',
    verticalAlign: 'bottom',
  },
};

/**
 * constant for echart options
 * @constant ZOOM
 */
export const ZOOM: DataZoomComponentOption[] = [
  {
    start: 0,
    type: 'slider',
    disabled: false,
  },
  {
    type: 'inside',
    xAxisIndex: [0],
    start: 0,
  },
  {
    type: 'inside',
    yAxisIndex: [0],
    start: 0,
  },
];

/**
 * constant for echart options
 * @constant LEGEND
 */
export const LEGEND: LegendComponentOption = {
  orient: 'vertical',
  backgroundColor: '#FFF',
  borderColor: COLORS.borderColor,
  borderWidth: 1,
  padding: [10, 15],
  borderRadius: 8,
  itemGap: 10,
  height: '50%',
  bottom: '86%',
  right: '10%',
};

/**
 * constant for echart options
 * @constant TOOLTIP
 */
export const TOOLTIP: TooltipComponentOption = {
  trigger: 'axis',
  padding: 15,
  borderRadius: 8,
  axisPointer: {
    label: {
      backgroundColor: COLORS.bgColor,
      formatter: (params) => parseInt(`${params.value}`).toString(),
    },
    show: true,
    lineStyle: {
      type: 'dashed',
      width: 1,
    },
  },
  valueFormatter: (value) => (parseFloat(`${value}`) ? parseFloat(`${value}`).toFixed(3) : '-'),
};
export const MARKLINE: MarkLineComponentOption = {
  symbol: ['none', 'none'],
  label: { show: false },
  silent: true,
  lineStyle: {
    type: 'dashed',
    color: '#8C9294',
  },
};
export const MARKAREA: MarkAreaComponentOption = {
  silent: false,
  itemStyle: {
    color: '#F7F9FA',
    opacity: 0.01,
    borderJoin: 'bevel',
    shadowOffsetX: 1,
  },
};
