<app-ui-accordion [uniqId]="uniqId" (click)="accordionOpened($event)" (keydown)="accordionOpened($event)">
  <div id="title" title>Forecast parameters</div>
  <div id="additional-details" additional-details>
    <div class="import-flowers" (keydown)="importFlowersData()" (click)="importFlowersData()">
      <span class="import-icon"><img src="./assets/images/iconLeft.svg" alt="import flowers"></span>
      <span class="import-text">Import flowers data</span>
    </div>
  </div>
  <div id="body" class="container" body>
    <div #periodDiv class="scrollableDiv" *ngIf="periodsFormGroup">
      <div *ngFor="let period of periodsFormGroup.controls; let index = index" id="{{'element-' + index}}">
        <app-forecast-parameters-one-period [index]="index"></app-forecast-parameters-one-period>
      </div>
    </div>
    <div class="add-period" *ngIf="periodsFormGroup && periodsFormGroup.controls.length < maxNumberOfPeriods">
      <button type="button" id="add-period-cta" (click)="addPeriod()">
        <span class="add-icon"><img src="./assets/images/add.svg" alt="add period"></span>
        <span class="add-text">Add a period</span>
      </button>
    </div>
  </div>
</app-ui-accordion>