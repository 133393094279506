import { Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EndSessionRequest, EventMessage, EventType, RedirectRequest } from '@azure/msal-browser';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { ConnectedUser } from '../types/connected-user.interface';
import { EventError } from '@azure/msal-browser/dist/event/EventMessage';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  authenticated = new BehaviorSubject(false);

  public connectedUser: BehaviorSubject<ConnectedUser | null> = new BehaviorSubject<ConnectedUser | null>(null);

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private router: Router,
  ) {
    // Init Service
    this._activeFirstAccountFromSessionStorage();
    this._loadActiveAccount();

    // Launch action on event from MSAL service
    this.msalBroadcastService.msalSubject$.subscribe({
      next: (event: EventMessage) => {
        switch (event.eventType) {
          case EventType.LOGIN_SUCCESS:
          case EventType.ACQUIRE_TOKEN_SUCCESS: {
            const payload = event.payload as AuthenticationResult;
            this._onLogin(payload.account);
            break;
          }

          case EventType.LOGOUT_SUCCESS: {
            this._onLogout();
            break;
          }

          case EventType.LOGIN_FAILURE:
          case EventType.ACQUIRE_TOKEN_FAILURE: {
            this._onLoginFailure(event.error);
            break;
          }

          default: {
            break;
          }
        }
      },
    });
  }

  public login(redirectUrl = '/') {
    this.msalService.instance
      .handleRedirectPromise()
      .then((_) => {
        this.msalService.instance.loginRedirect({
          redirectStartPage: redirectUrl,
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      })
      .catch((_) => {
        console.log('FAILED_HANDLE_REDIRECT');
      });
  }

  public logout(logoutRequest?: EndSessionRequest) {
    this.msalService.logout(logoutRequest);
  }

  private _onLogin(account: AccountInfo | null) {
    if (!account) {
      return;
    }
    this.msalService.instance.setActiveAccount(account);
    this._loadActiveAccount();
  }

  private _onLogout() {
    this.authenticated.next(false);
    this.connectedUser.next(null);
    this.router.navigate(['/login']);
  }

  private _onLoginFailure(error: EventError) {
    this.authenticated.next(false);
    this.connectedUser.next(null);
    console.error(error);
    this.router.navigate(['/unauthorized']);
  }

  private _accountInfoToConnectedUser(accountInfo: AccountInfo | null): null | ConnectedUser {
    if (!accountInfo) return null;

    return {
      username: accountInfo.name,
      roles: accountInfo.idTokenClaims?.roles, 
    } as ConnectedUser;
  }

  private _loadActiveAccount() {
    if (!this.msalService.instance.getActiveAccount()) {
      return;
    }
    const activeAccount = this._accountInfoToConnectedUser(this.msalService.instance.getActiveAccount());
    this.connectedUser.next(activeAccount);
    this.authenticated.next(!!activeAccount);
  }

  private _activeFirstAccountFromSessionStorage() {
    const account = this.msalService.instance.getAllAccounts();
    if (account && account.length > 0) {
      this.msalService.instance.setActiveAccount(account[0]);
    }
  }

  redirectAuthenticatedUserToHome() {
    // Redirect user if is already authenticated
    if (this.msalService.instance.getActiveAccount() !== null) {
      this.router.navigate(['/']);
    }
  }
}
