import { AfterViewInit, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ENDPOINTS } from 'src/app/shared/constants/endpoints';
import { HttpService } from 'src/app/shared/services/http.service';

@Component({
  selector: 'app-catwin-info',
  templateUrl: './catwin-info.component.html',
  styleUrl: './catwin-info.component.scss',
})
export class CatwinInfoComponent implements AfterViewInit{
  constructor(private matDialog: MatDialog, private httpService: HttpService, private activatedRoute: ActivatedRoute) {}
  siteInfo: any;

  ngAfterViewInit() {    
    this.httpService.getById(ENDPOINTS.parametersConfig, { paramName: 'type', id: 'SiteInfo' }).subscribe((config:any) => {      
      this.siteInfo = JSON.parse(config.configurationData).siteInfo;      
    });
  }

  public downloadDocLink(docType: string) {
    const refineryCode = this.activatedRoute.children[0].children[0].snapshot.params['refineryCode'];
    const docTypeFileName = docType === 'user-guide' ? 'User_Guide.pdf' : 'Glossary.pdf';
    const link = document.createElement('a');
    const filePath = './assets/documents/'+ refineryCode +'/'+ docTypeFileName;
    link.href = filePath;
    link.download = docTypeFileName;
    link.click();
  }
}
