import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParamConfigService {

  paramsConfig:any;

  constructor(){
    this.paramsConfig = {};    
  }

  setParamsConfig(config: any){
    this.paramsConfig = config;
  }

  getParamsConfig(){
    return this.paramsConfig;
  }
}
