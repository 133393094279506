import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UtilsService } from './utils/utils.service';
import * as XLSX from 'xlsx';
import { IForecastCatalystParametersToExport } from 'src/app/types/forecast';
import { ForecastResultToExport, ForecastedStatesToExport } from 'src/app/types/forecast-results';
import { ForecastService } from 'src/app/forecast/services/forecast.service';
import { FlowerService } from 'src/app/forecast/services/flower.service';
@Component({
  selector: 'app-ui-export-btn',
  templateUrl: './ui-export-btn.component.html',
  styleUrls: ['./ui-export-btn.component.scss'],
})
export class UiExportBtnComponent {
  constructor(
    private datePipe: DatePipe,
    private utilsService: UtilsService,
    private forecastService: ForecastService,
    private flowerService: FlowerService
  ) {}
  fileName = `forecast_export_${this.datePipe.transform(Date.now(), 'short')}.xlsx`;

  export(): void {
    let outputData: IForecastCatalystParametersToExport | null = null;
    let forecastResult: ForecastResultToExport | null = null;
    let flowersResult: ForecastedStatesToExport | null = null;

    const input = this.utilsService.getInputForExport();
    const output = this.utilsService.getOutputForExport();
    
    this.forecastService
      .getLastFlowersFile().subscribe(result => {        
    
    const flower = this.flowerService.formatFlowerData(result.flowersData);

    if (flower) flowersResult = this.utilsService.exportFlowersToExcel(flower);
    if (output) forecastResult = this.utilsService.exportOutputToExcel(output.forecastedStates, output.testRuns);
    if (input) outputData = this.utilsService.exportInputToExcel(input.simulCOP, input.cc);
    if (outputData && forecastResult && flowersResult) {
      // set input sheets
      const firstColumnWidth = this.utilsService.columnWidth().inputFirstColumnWidth;
      const forecast = XLSX.utils.json_to_sheet(outputData.forecast.values, { header: outputData.forecast.header });
      const catalyst = XLSX.utils.json_to_sheet(outputData.catalyst.values, { header: outputData.catalyst.header });
      if (forecast) forecast['!cols'] = firstColumnWidth;
      if (catalyst) catalyst['!cols'] = firstColumnWidth;

      // set output sheet
      const forecastedStates = XLSX.utils.json_to_sheet(forecastResult.forecastedStates.values, { header: forecastResult.forecastedStates.header });
      const testRuns = XLSX.utils.json_to_sheet(forecastResult.testRuns.values, { header: forecastResult.testRuns.header });
      if (forecastedStates) forecastedStates['!cols'] = this.utilsService.columnWidth().forecastColumnWidth;
      if (testRuns) testRuns['!cols'] = this.utilsService.columnWidth().testRunColumnWidth;

      // set flowers data sheet
      const flowersData = XLSX.utils.json_to_sheet(flowersResult.values, { header: flowersResult.header });
      if (flowersData) flowersData['!cols'] = this.utilsService.columnWidth(flowersResult.header).flowerColumnWidth;
      
      // create workbook
      const workbook: XLSX.WorkBook = {
        Sheets: {
          FORECAST_PARAMETERS: forecast,
          CATALYST_PARAMETERS: catalyst,
          OUTPUTS_FORECAST_STATES: forecastedStates,
          OUTPUTS_TEST_RUNS: testRuns,
          FLOWERS_DATA: flowersData
        },
        SheetNames: ['FORECAST_PARAMETERS', 'CATALYST_PARAMETERS', 'OUTPUTS_FORECAST_STATES', 'OUTPUTS_TEST_RUNS', 'FLOWERS_DATA'],
      };

      // download file
      XLSX.writeFile(workbook, this.fileName, { cellStyles: true });
    }
  }
  );}
}
