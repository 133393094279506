import { BrowserCacheLocation, IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../../environments/environment';
import { LogLevel } from '@azure/msal-common';
import { MsalGuardConfiguration } from '@azure/msal-angular/msal.guard.config';
import { MsalInterceptorConfiguration } from '@azure/msal-angular';

export function MSALInstanceFactory(): IPublicClientApplication {
  const pca = new PublicClientApplication({
    auth: {
      clientId: environment.authentication.clientId,
      authority: environment.authentication.authority,
      redirectUri: environment.authentication.redirectUri,
      postLogoutRedirectUri: '/login',
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        piiLoggingEnabled: false,
        logLevel: LogLevel.Info,
      },
    },
  });
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/v2-migration.md
  pca.initialize();
  return pca;
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    // authRequest: {
    //   scopes: ['user.read'],
    // },
    // loginFailedRoute: '/unauthorized',
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/', ['user.read']);
  protectedResourceMap.set(environment.authentication.backend.uri, environment.authentication.backend.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}
