import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormArray, FormGroupDirective } from '@angular/forms';
import { ForecastFormService } from '../services/forecast-form.service';
import { ParamConfigService } from 'src/app/shared/services/site-config/param-config.service';

@Component({
  selector: 'app-forecast-parameters-one-period',
  templateUrl: './forecast-parameters-one-period.component.html',
  styleUrls: ['./forecast-parameters-one-period.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class ForecastParametersOnePeriodComponent implements OnInit {
  @Input() index = 0;
  cCOSwitch = false;
  periodsForm?: FormArray;
  forecastParamConfig: any;

  constructor(
    public formGroupDirective: FormGroupDirective,
    public forecastFormService: ForecastFormService,
    private paramConfigService: ParamConfigService
  ) {
    this.forecastParamConfig = this.paramConfigService.getParamsConfig().forecast;    
  }

  ngOnInit() {
    this.periodsForm = this.formGroupDirective.form?.get('periods') as FormArray;
    const currentPeriodForm = this.periodsForm?.controls[this.index];

    currentPeriodForm?.get('hasChangeOut')?.valueChanges.subscribe((value) => {
      this.forecastFormService.updateCCO(currentPeriodForm, value);
    });

    // periodsForm[index] copies the previous period so it inherits the hasChangeOut.
    this.cCOSwitch = currentPeriodForm?.get('hasChangeOut')?.value;
    this.forecastFormService.updateCCO(currentPeriodForm, this.cCOSwitch);
  }

  removePeriod(index: number) {
    this.periodsForm?.removeAt(index);
  }

  slideValue(event: Event) {
    this.cCOSwitch = (event.target as HTMLInputElement).checked;
  }
}
