import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { forecastFormValidators, forecastFormValidatorsNoMandatory } from 'src/app/shared/constants/form-validators';
import { ChangeOutOptions, ForecastParameters, IForecast } from 'src/app/types/forecast';

@Injectable({
  providedIn: 'root',
})
export class ForecastFormService {
  constructor(private fb: FormBuilder) {}

  /**
   * @function createForm create forecast form
   * @returns formGroup
   */
  createForm(lastSavedForecastInput?: IForecast): FormGroup {
    return this.fb.group({
      actConv: [lastSavedForecastInput?.cc.act.conv, forecastFormValidators],
      actHds: [lastSavedForecastInput?.cc.act.hds, forecastFormValidators],
      actHdn: [lastSavedForecastInput?.cc.act.hdn, forecastFormValidators],
      abscConv: [lastSavedForecastInput?.cc.absC.conv, forecastFormValidators],
      abscHds: [lastSavedForecastInput?.cc.absC.hds, forecastFormValidators],
      abscHdn: [lastSavedForecastInput?.cc.absC.hdn, forecastFormValidators],
      absmConv: [lastSavedForecastInput?.cc.absM.conv, forecastFormValidators],
      absmHds: [lastSavedForecastInput?.cc.absM.hds, forecastFormValidators],
      absmHdn: [lastSavedForecastInput?.cc.absM.hdn, forecastFormValidators],
      periods: this.createPeriods(lastSavedForecastInput?.simulCOP),
    });
  }

  /**
   * @function createPeriods create forecast parameters periods
   * @returns FormArray
   */
  public createPeriods(forecastParameters?: ForecastParameters[]): FormArray {
    const FormArray: FormArray = this.fb.array([]);
    if (forecastParameters != undefined) {
      forecastParameters?.forEach((forecastParameter) => {
        FormArray.push(this.createSinglePeriod(forecastParameter));
      });
    } else {
      FormArray.push(this.createSinglePeriod());
    }
    return FormArray;
  }

  public initCCO(changeOutActivate: boolean, changeOutOptions?: ChangeOutOptions) {
    return this.fb.group({
      durationInDays: [changeOutOptions?.durationInDays, changeOutActivate ? forecastFormValidators : forecastFormValidatorsNoMandatory],
      mocDemetCat: [changeOutOptions?.mocDemetCat, changeOutActivate ? forecastFormValidators : forecastFormValidatorsNoMandatory],
      mocMainCat: [changeOutOptions?.mocMainCat, changeOutActivate ? forecastFormValidators : forecastFormValidatorsNoMandatory],
      cokeBuildUp: [changeOutOptions?.cokeBuildUp, changeOutActivate ? forecastFormValidators : forecastFormValidatorsNoMandatory],
    });
  }

  public updateCCO(formGroup: AbstractControl, changeOutActivated: boolean): void {
    const changeOutOptions = formGroup?.get('changeOutOptions');
    if (changeOutOptions != null) {
      const ccoFormInputsNames = ['durationInDays', 'mocDemetCat', 'mocMainCat', 'cokeBuildUp'];
      this.redefineNumbersValidators(changeOutActivated, ccoFormInputsNames, changeOutOptions);
    }
  }

  private redefineNumbersValidators(isMandatory: boolean, inputsNames: string[], formGroup: AbstractControl): void {
    inputsNames.forEach((val) => {
      formGroup?.get(val)?.setValidators(isMandatory ? forecastFormValidators : forecastFormValidatorsNoMandatory);
      formGroup?.get(val)?.updateValueAndValidity();
    });
  }

  /**
   * @function getNewPeriodWithDefaultValues create new forecast parameters form pre-filled with the values from the previous period
   * @returns formGroup
   */
  public getNewPeriodWithDefaultValues(previousFormGroup: any): FormGroup {
    const formGroup = this.createSinglePeriod();
    formGroup.patchValue(previousFormGroup.value);
    return formGroup;
  }

  /**
   * @function createSinglePeriod create forecast parameters single period
   * @returns FormGroup
   */
  private createSinglePeriod(forecastParameters?: ForecastParameters): FormGroup {
    return this.fb.group({
      duration: [forecastParameters?.duration, forecastFormValidators],
      d15: [forecastParameters?.d15, forecastFormValidators],
      svalue: [forecastParameters?.svalue, forecastFormValidators],
      ccr: [forecastParameters?.ccr, forecastFormValidators],
      nvalue: [forecastParameters?.nvalue, forecastFormValidators],
      t50: [forecastParameters?.t50, forecastFormValidators],
      evap370: [forecastParameters?.evap370, forecastFormValidators],
      metals: [forecastParameters?.metals, forecastFormValidators],
      flowRate: [forecastParameters?.flowRate, forecastFormValidators],
      ppH2: [forecastParameters?.ppH2, forecastFormValidators],
      h2Hc: [forecastParameters?.h2HC, forecastFormValidators],
      catalystVolume: [forecastParameters?.catalystVolume, forecastFormValidators],
      maxWabt: [forecastParameters?.maxWABT, forecastFormValidators],
      tlpSulfurTarget: [forecastParameters?.tlpSulfurTarget, forecastFormValidators],
      hasChangeOut: [!!forecastParameters?.changeOutOptions],
      changeOutOptions: this.initCCO(!!forecastParameters?.changeOutOptions, forecastParameters?.changeOutOptions),
    });
  }
}
