import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { ControlContainer, FormArray, FormGroupDirective } from '@angular/forms';
import { ForecastFormService } from '../services/forecast-form.service';
import { MAX_NUMBER_OF_PERIODS } from 'src/app/shared/constants/forecast';
import { MatDialog } from '@angular/material/dialog';
import { FlowersDataModalComponent } from '../flowers-data-modal/flowers-data-modal.component';
import { ForecastService } from '../services/forecast.service';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { switchMap } from 'rxjs';

@Component({
  selector: 'app-forecast-parameters',
  templateUrl: './forecast-parameters.component.html',
  styleUrls: ['./forecast-parameters.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class ForecastParametersComponent {
  @ViewChild('periodDiv') periodDiv?: ElementRef;
  @Output() isAccordionOpened = new EventEmitter<boolean>();

  public maxNumberOfPeriods = MAX_NUMBER_OF_PERIODS;
  constructor(
    public inputForecastForm: FormGroupDirective,
    private forecastFormService: ForecastFormService,
    private matDialog: MatDialog,
    private forecastService: ForecastService,
    private modalService: ModalService,
  ) {}

  public uniqId = 'forecast';

  /**
   * getter that return periods fromArray
   * @returns periods fromArray
   */
  get periodsFormGroup() {
    if (this.inputForecastForm.form) {
      return this.inputForecastForm.form.get('periods') as FormArray;
    }
    return null;
  }

  /**
   * @function addPeriod adds a period to the FormArray periods
   */
  addPeriod() {
    const lastPeriod = this.periodsFormGroup?.at(this.periodsFormGroup?.length - 1);
    this.periodsFormGroup?.push(this.forecastFormService.getNewPeriodWithDefaultValues(lastPeriod));
    setTimeout(() => {
      this.scrollOnLastPeriod();
    }, 0);
  }

  accordionOpened(event: Event) {
    const target = event.target as HTMLElement;
    if ((target.id && target.id === 'title') || target.id === 'accordion-button-forecast') {
      this.isAccordionOpened.emit(true);
    }
  }

  private scrollOnLastPeriod() {
    const lastElementIndex = this.periodsFormGroup ? this.periodsFormGroup?.length - 1 : 0;
    const periodsContainer = this.periodDiv?.nativeElement;
    if (periodsContainer) {
      const periodToAdd = periodsContainer.querySelector(`#element-${lastElementIndex}`);
      if (periodToAdd) {
        window.scrollTo({ top: 0 });
        periodsContainer.scrollTo({
          left: 3000,
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  }

  /**
   * @function importFlowersData imports flowers data
   */
  importFlowersData() {
    this.forecastService
      .getLastFlowersFile()
      .pipe(
        switchMap((result) => {
          const dialogRef = this.matDialog.open(FlowersDataModalComponent, {
            panelClass: 'dialog-generic-container',
            data: result?.flowersData,
            width: '1230px',
          });
          return dialogRef.afterClosed();
        }),
      )
      .subscribe({
        next: (result) => {
          if (result && this.inputForecastForm.form) {
            this.inputForecastForm.form.setControl('periods', this.forecastFormService.createPeriods(result));
          }
        },
        error: (_) => {
          this.modalService.showErrorModal({ title: 'Failed to send the request.', type: 'error', message: 'The request failed. Try again later.' });
        },
      });
  }
}
