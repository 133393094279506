import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { IForecastDisplayPreference } from 'src/app/types/forecast-display-preference';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-filter-forecast-activity',
  templateUrl: './filter-forecast-activity.component.html',
  styleUrls: ['./filter-forecast-activity.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class FilterForecastActivityComponent implements OnChanges {
  @Output() intervalChanged = new EventEmitter<number>();
  @Output() sorSelected = new EventEmitter<Date>();
  @Input() lastDate?: IForecastDisplayPreference | null;
  public date!: moment.Moment;

  ngOnChanges(): void {
    if (this.lastDate) this.date = moment(this.lastDate.start_of_run);
  }

  changeInterval(interval: string) {
    if (this.isValidInterval(interval)) {
      this.intervalChanged.emit(Number(interval));
    }
  }

  isValidInterval(interval: string) {
    return Number(interval) >= 1 && Number.isInteger(Number(interval));
  }

  getSelectedDate(selectedDate: Date) {
    this.sorSelected.emit(new Date(selectedDate));
  }
}
