<div *ngIf="formGroupDirective.form" formArrayName="periods">
    <div class="forecast-container" id="forecast-period-{{index}}" [formGroupName]="index" >
      <div class="row title">
        <div class="col header" [ngClass]="{'period-title': index > 0}">Period {{index + 1}}</div>
        <div class="col header"  *ngIf="index > 0" [ngClass]="{'period-actions': index > 0}">
          <button type="button" (click)="removePeriod(index)" id="remove-period-{{index}}">
            <span><img src="./assets/images/delete.svg" alt="delete period"></span>
            <span>Delete</span>
          </button>
      </div>
      </div>
      <div class="m-2 content">
        <div class="form-check form-switch h6 ms-31">          
          <label class="form-check-label" for="cCOSwitch-{{index}}">Catalyst ChangeOut</label>
          <input class="form-check-input" type="checkbox" id="cCOSwitch-{{index}}"  formControlName="hasChangeOut" (change)="slideValue($event)">
        </div>
        <div *ngIf="cCOSwitch" formGroupName="changeOutOptions">
          <div class="row">
            <div class="col-5 row-header sub-title">
              <span>{{forecastParamConfig?.changeOutOptions?.durationInDays?.name}}</span> 
            </div>
            <div class="col text-center">
              <input
                type="text"
                class="form-control"
                formControlName="durationInDays"
              />
              <span>{{forecastParamConfig?.changeOutOptions?.durationInDays?.unit}}</span>
            </div>
          </div>
          <div class="row bg-white">
            <div class="col-5 row-header sub-title">
              <span>{{forecastParamConfig?.changeOutOptions?.mocDemetCat?.name}}</span> 
            </div>
            <div class="col text-center">
              <input
                type="text"
                class="form-control"
                formControlName="mocDemetCat"
              />
              <span>{{forecastParamConfig?.changeOutOptions?.mocDemetCat?.unit}}</span>
            </div>
          </div><div class="row">
          <div class="col-5 row-header sub-title">
            <span>{{forecastParamConfig?.changeOutOptions.mocMainCat?.name}}</span> 
          </div>
          <div class="col text-center">
            <input
              type="text"
              class="form-control"
              formControlName="mocMainCat"
            />
            <span>{{forecastParamConfig?.changeOutOptions?.mocMainCat?.unit}}</span>
          </div>
        </div>
          <div class="row bg-white">
            <div class="col-5 row-header sub-title">
              <span>{{forecastParamConfig?.changeOutOptions?.cokeBuildUp?.name}}</span> 
            </div>
            <div class="col text-center">
              <input
                type="text"
                class="form-control"
                formControlName="cokeBuildUp"
              />
              <span></span>
            </div>
          </div>
        </div>

        <div class="row bg-white" >
          <div class="col-5 row-header sub-title">
            <span>{{forecastParamConfig?.duration?.name}}</span> 
          </div>
          <div class="col text-center">
            <input type="text" class="form-control" formControlName="duration" />
            <span>{{forecastParamConfig?.duration?.unit}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-5 row-header sub-title">
            <span>{{forecastParamConfig?.d15?.name}}</span>
          </div>
          <div class="col text-center">
            <input type="text" class="form-control" formControlName="d15" />
            <span>{{forecastParamConfig?.d15?.unit}}</span>
          </div>
        </div>
        <div class="row bg-white">
          <div class="col-5 row-header sub-title">
            <span>{{forecastParamConfig?.svalue?.name}}</span> 
          </div>
          <div class="col text-center">
            <input type="text" class="form-control" formControlName="svalue" />
            <span>{{forecastParamConfig?.svalue?.unit}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-5 row-header sub-title">
            <span>{{forecastParamConfig?.ccr?.name}}</span> 
          </div>
          <div class="col text-center">
            <input type="text" class="form-control" formControlName="ccr" />
            <span>{{forecastParamConfig?.ccr?.unit}}</span>
          </div>
        </div>
        <div class="row bg-white">
          <div class="col-5 row-header sub-title">
            <span>{{forecastParamConfig?.nvalue?.name}}</span> 
          </div>
          <div class="col text-center">
            <input type="text" class="form-control" formControlName="nvalue" />
            <span>{{forecastParamConfig?.nvalue?.unit}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-5 row-header sub-title">
            <span>{{forecastParamConfig?.t50?.name}}</span> 
          </div>
          <div class="col text-center">
            <input type="text" class="form-control" formControlName="t50" />
            <span>{{forecastParamConfig?.t50?.unit}}</span>
          </div>
        </div>
        <div class="row bg-white">
          <div class="col-5 row-header sub-title">
            <span>{{forecastParamConfig?.evap370?.name}}</span> 
          </div>
          <div class="col text-center">
            <input type="text" class="form-control" formControlName="evap370" />
            <span>{{forecastParamConfig?.evap370?.unit}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-5 row-header sub-title">
            <span>{{forecastParamConfig?.metals?.name}}</span> 
          </div>
          <div class="col text-center">
            <input type="text" class="form-control" formControlName="metals" />
            <span>{{forecastParamConfig?.metals?.unit}}</span>
          </div>
        </div>
        <div class="row bg-white">
          <div class="col-5 row-header sub-title">
            <span>{{forecastParamConfig?.flowRate?.name}}</span> 
          </div>
          <div class="col text-center">
            <input type="text" class="form-control" formControlName="flowRate" />
            <span>{{forecastParamConfig?.flowRate?.unit}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-5 row-header sub-title">
            <span>{{forecastParamConfig?.ppH2?.name}}</span> 
          </div>
          <div class="col text-center">
            <input type="text" class="form-control" formControlName="ppH2" />
            <span>{{forecastParamConfig?.ppH2?.unit}}</span>
          </div>
        </div>
        <div class="row bg-white">
          <div class="col-5 row-header sub-title">
            <span>{{forecastParamConfig?.h2HC?.name}}</span> 
          </div>
          <div class="col text-center">
            <input type="text" class="form-control" formControlName="h2Hc" />
            <span>{{forecastParamConfig?.h2HC?.unit}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-5 row-header sub-title">
            <span>{{forecastParamConfig?.catalystVolume?.name}}</span> 
          </div>
          <div class="col text-center">
            <input type="text" class="form-control" formControlName="catalystVolume" />
            <span>{{forecastParamConfig?.catalystVolume?.unit}}</span>
          </div>
        </div>
        <div class="row bg-white">
          <div class="col-5 row-header sub-title">
            <span>{{forecastParamConfig?.maxWABT?.name}}</span> 
          </div>
          <div class="col text-center">
            <input type="text" class="form-control" formControlName="maxWabt" />
            <span>{{forecastParamConfig?.maxWABT?.unit}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-5 row-header sub-title">
            <span>{{forecastParamConfig?.tlpSulfurTarget?.name}}</span> 
          </div>
          <div class="col text-center">
            <input type="text" class="form-control" formControlName="tlpSulfurTarget" />
            <span>{{forecastParamConfig?.tlpSulfurTarget?.unit}}</span>
          </div>
        </div>

      </div>
    </div>
</div>
