import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HomepageRoutingModule } from './homepage-routing.module';
import { HomepageComponent } from './homepage.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [HomepageComponent],
  imports: [HomepageRoutingModule, SharedModule, FormsModule],
})
export class HomepageModule {}
