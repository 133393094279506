<div class="d-flex justify-content-between header-wrapper m-4">
  <div class="header h4">Forecast parameters</div>
  <div class="d-flex gap-2">
  <button class="btn btn-secondary" [mat-dialog-close]="false">Cancel</button>
  <button class="btn btn-primary" (click)="load()">Load</button>
  </div>
</div>

<div mat-dialog-content>
  <div class="flex-column gap-2 d-flex">
    <app-period-picker [dataFromFlowers]="formattedData" (periodChanged)="getPeriodChanged($event)"></app-period-picker>
    <div class="flowers-data-container">
      <div>
        <div class="sub-title bg-white"> <span>Period</span><span></span></div>
        <div class="sub-title"><span>Days</span><span></span></div>
        <div class="sub-title bg-white"> <span>{{this.paramConfig?.forecast?.d15?.name}}</span> <span>{{this.paramConfig?.forecast?.d15?.unit}}</span></div>
        <div class="sub-title"> <span>{{this.paramConfig?.forecast?.svalue?.name}}</span> <span>{{this.paramConfig?.forecast?.svalue?.unit}}</span></div>
        <div class="sub-title bg-white"> <span>{{this.paramConfig?.forecast?.ccr?.name}}</span> <span>{{this.paramConfig?.forecast?.ccr?.unit}}</span></div>
        <div class="sub-title"> <span>{{this.paramConfig?.forecast?.nvalue?.name}}</span> <span>{{this.paramConfig?.forecast?.nvalue?.unit}}</span></div>
        <div class="sub-title bg-white"> <span>{{this.paramConfig?.forecast?.t50?.name}}</span> <span>{{this.paramConfig?.forecast?.t50?.unit}}</span></div>
        <div class="sub-title"> <span>{{this.paramConfig?.forecast?.evap370?.name}}</span> <span>{{this.paramConfig?.forecast?.evap370?.unit}}</span></div>
        <div class="sub-title bg-white"><span>{{this.paramConfig?.forecast?.metals?.name}}</span> <span>{{this.paramConfig?.forecast?.metals?.unit}}</span></div>
        <div class="sub-title"> <span>{{this.paramConfig?.forecast?.flowRate?.name}}</span> <span>{{this.paramConfig?.forecast?.flowRate?.unit}}</span></div>
        <div class="sub-title bg-white"> <span>{{this.paramConfig?.forecast?.ppH2?.name}}</span> <span>{{this.paramConfig?.forecast?.ppH2?.unit}}</span></div>
        <div class="sub-title"> <span>{{this.paramConfig?.forecast?.h2HC?.name}}</span> <span>{{this.paramConfig?.forecast?.h2HC?.unit}}</span></div>
        <div class="sub-title bg-white"> <span>{{this.paramConfig?.forecast?.catalystVolume?.name}}</span> <span>{{this.paramConfig?.forecast?.catalystVolume?.unit}}</span></div>

      </div>
      <div *ngFor="let data of formattedData">
        <div class="flowers-data bg-white"> {{data.period }}</div>
        <div class="flowers-data date">{{data.date | date: 'dd/MM/yy'}}</div>
        <div class="flowers-data bg-white">{{data.d15 | number: '0.1-1' }}</div>
        <div class="flowers-data">{{data.svalue | number: '0.1-1' }}</div>
        <div class="flowers-data bg-white">{{data.ccr | number: '0.1-1' }}</div>
        <div class="flowers-data">{{data.nvalue | number: '0.1-1' }}</div>
        <div class="flowers-data bg-white">{{data.t50 | number: '0.1-1' }}</div>
        <div class="flowers-data">{{data.evap370 | number: '0.1-1' }}</div>
        <div class="flowers-data bg-white">{{data.metals | number: '0.1-1' }}</div>
        <div class="flowers-data">{{data.flowRate | number: '0.1-1' }}</div>
        <div class="flowers-data bg-white">{{data.ppH2 | number: '0.1-1' }}</div>
        <div class="flowers-data">{{data.h2HC | number: '0.1-1'}}</div>
        <div class="flowers-data bg-white">{{data.catalystVolume | number: '0.1-1'}}</div>

      </div>
    </div>
  </div>
</div>
