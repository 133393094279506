<div class="header-wrapper">
  <div class="header h4">{{ data.title }}</div>
</div>

<div mat-dialog-content class="d-flex gap-1" id="error-message">
    <span class="material-symbols-outlined">{{mainMessageIcon}}</span>
    <div>{{ data.message }}</div>
</div>
<mat-dialog-actions align="end" class="p-3">
  <button id="confirmation-button" [mat-dialog-close]="true" class="btn btn-primary">
    <mat-icon *ngIf="data.confirmationButtonIcon">
      {{ data.confirmationButtonIcon }}
    </mat-icon>
    {{ okText }}
  </button>
</mat-dialog-actions>
