<div class="card w-100 filter-forecast-container">
  <div class="card-body justify-content-between p-sm-4 w-25 d-flex gap-2 h-100">
    <div class="d-flex flex-column">
      <label class="form-label" for="interval">Interval (Days)</label>
      <mat-form-field appearance="outline">
      <input matInput id="interval" type="number" min="1" value="1"
             [ngClass]="{'invalid-interval': !isValidInterval(interval.value)}"
             #interval (input)="changeInterval(interval.value)" />
      </mat-form-field>
    </div>
    <div class="d-flex flex-column">
    <label class="form-label">Start of run date</label>
    <mat-form-field appearance="outline">
      <input matInput [matDatepicker]="picker" (dateInput)="getSelectedDate($event.value)" [value]="date" readonly="true">
      <mat-datepicker-toggle matIconSuffix [for]="picker">
        <mat-icon matDatepickerToggleIcon class="mat-icon-base-red">calendar_today</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    </div>
  </div>
</div>
