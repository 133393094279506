<div class="card">
  <div class="d-flex card-body">
    <div class="gap-1" *ngFor="let period of periods; index as i">
      <mat-chip-option (removed)="remove(period)">
        <div class="fw-bold">{{period.periodName}} {{i + 1}}</div> <div class="fw-light"> ({{period.periodDuration | number: '0.0'}} days)</div>
        <button matChipRemove aria-label="Remove period">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-option>
    </div>
    <div>

      <div [hidden]="!showCalendar">
        <mat-card class="calendar">
          <mat-calendar [(selected)]="selectedDateRange" (selectedChange)="rangeChanged($event)" [minDate]="minDate" [maxDate]="maxDate"></mat-calendar>
          <mat-card-actions>
            <button class="btn btn-secondary" (click)="toggleCalendar()">Cancel</button>
            <button class="btn btn-primary" (click)="addPeriod()">Apply</button>
          </mat-card-actions>
        </mat-card>
      </div>

      <div class="picker" (keydown)="toggleCalendar()" (click)="toggleCalendar()" [ngClass]="{'display-add-btn':showCalendar}">
        <span class="material-symbols-outlined">add</span><div>Add a period</div>
      </div>
    </div>
  </div>
</div>
