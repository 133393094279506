import { Component, Input } from '@angular/core';
import { ForecastService } from 'src/app/forecast/services/forecast.service';

@Component({
  selector: 'app-ui-accordion',
  templateUrl: './ui-accordion.component.html',
  styleUrls: ['./ui-accordion.component.scss'],
})
export class UiAccordionComponent {
  @Input() uniqId?: string;

  constructor(private forecastService: ForecastService) {
    this.forecastService.getCloseAllAccordions().subscribe((_) => {
      const accordionButton = document.getElementById('accordion-button-' + this.uniqId);
      if (accordionButton && !accordionButton.classList.contains('collapsed')) {
        accordionButton.click();
      }
    });
  }
}
