import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { PeriodFlowers } from '../../../types/period-flowers';
import { DateRange, DefaultMatCalendarRangeStrategy, MatRangeDateSelectionModel } from '@angular/material/datepicker';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DateAdapter } from '@angular/material/core';
import { ForecastParameters } from '../../../types/forecast';

@Component({
  selector: 'app-period-picker',
  templateUrl: './period-picker.component.html',
  styleUrls: ['./period-picker.component.scss'],
})
export class PeriodPickerComponent implements OnChanges {
  periods: PeriodFlowers[] = [];
  minDate?: Moment | null;
  maxDate?: Moment | null;
  showCalendar = false;
  selectedDateRange?: DateRange<Moment>;
  minDateFromFlowers: Moment = moment();
  @Output() periodChanged = new EventEmitter<ForecastParameters[]>();
  @Input() dataFromFlowers: ForecastParameters[] = [];

  constructor(
    private readonly selectionModel: MatRangeDateSelectionModel<Moment>,
    private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Moment>,
    public dateAdapter: DateAdapter<Moment>,
  ) {}

  ngOnChanges() {
    const minDataFromFlowers = this.dataFromFlowers.at(0)?.date;
    const maxDataFromFlowers = this.dataFromFlowers.at(this.dataFromFlowers.length - 1)?.date;
    this.minDateFromFlowers = moment(minDataFromFlowers, 'YYYY-MM-DD');
    const dMax = moment(maxDataFromFlowers, 'YYYY-MM-DD');
    this.minDate = this.dateAdapter.createDate(this.minDateFromFlowers.year(), this.minDateFromFlowers.month(), this.minDateFromFlowers.date());
    this.maxDate = this.dateAdapter.createDate(dMax.year(), dMax.month(), dMax.date());
  }

  rangeChanged(selectedDate: any) {
    const selection = this.selectionModel.selection,
      newSelection = this.selectionStrategy.selectionFinished(selectedDate, selection);
    this.selectionModel.updateSelection(newSelection, this);
    this.selectedDateRange = new DateRange<Moment>(newSelection.start, newSelection.end);
  }

  toggleCalendar() {
    this.showCalendar = !this.showCalendar;
  }

  addPeriod(): void {
    const d = moment(this.selectedDateRange?.end).add(1, 'd');
    this.minDate = this.dateAdapter.createDate(d.year(), d.month(), d.date());
    const days = moment(this.selectedDateRange?.end).diff(moment(this.selectedDateRange?.start), 'days');
    const index = this.periods.length;
    const periodFlowers: PeriodFlowers = {
      periodName: 'Period ',
      periodNumber: index + 1,
      periodDuration: days,
      startDate: this.selectedDateRange ? this.selectedDateRange.start : null,
      endDate: this.selectedDateRange ? this.selectedDateRange.end : null,
    };
    this.periods.push(periodFlowers);
    this.addDataIntoForecastParameters();
    this.toggleCalendar();
  }

  remove(period: PeriodFlowers) {
    const index = this.periods.indexOf(period);
    this.periods.splice(index, 1);
    this.periods.forEach((period, index) => (period.periodNumber = index + 1));
    const size = this.periods.length;
    if (size == 0) {
      this.minDate = this.dateAdapter.createDate(this.minDateFromFlowers.year(), this.minDateFromFlowers.month(), this.minDateFromFlowers.date());
      this.dataFromFlowers.forEach((fp) => (fp.period = undefined));
    } else {
      const d = moment(this.periods[size - 1].endDate).add(1, 'd');
      this.minDate = this.dateAdapter.createDate(d.year(), d.month(), d.date());
      this.assignPeriodToForecastParameters();
    }
  }

  private assignPeriodToForecastParameters(): void {
    this.dataFromFlowers.forEach((fp) => {
      const period = this.periods.find((p) => moment(fp.date) >= moment(p.startDate) && moment(fp.date) <= moment(p.endDate));
      fp.period = undefined;
      if (period) {
        fp.period = period.periodNumber;
        fp.duration = period.periodDuration;
      }
    });
  }

  addDataIntoForecastParameters() {
    this.assignPeriodToForecastParameters();
    this.periodChanged.emit(this.calculateAverageByKey(this.dataFromFlowers));
  }

  calculateAverageByKey(dataList: any): ForecastParameters[] {
    const forecastParameters: ForecastParameters[] = [];
    const groupByPeriod: { [p: number]: ForecastParameters[] } = dataList.reduce((acc: { [key: number]: ForecastParameters[] }, fp: ForecastParameters) => {
      const period = fp.period;
      if (period) {
        acc[period] = acc[period] || [];
        acc[period].push(fp);
      }
      return acc;
    }, {});

    Object.keys(groupByPeriod).forEach((period) => {
      let catVolSum = 0;
      let ccrSum = 0;
      let d15Sum = 0;
      let evap370Sum = 0;
      let flowRateSum = 0;
      let h2HCSum = 0;
      let maxWABTSum = 0;
      let metalsSum = 0;
      let nvalueSum = 0;
      let ppH2Sum = 0;
      let svalueSum = 0;
      let t50Sum = 0;
      let tlpSulfurTargetSum = 0;
      const fp = groupByPeriod[parseInt(period)];
      const count = fp.length;
      fp.forEach((forecast) => {
        catVolSum += forecast.catalystVolume || 0;
        ccrSum += forecast.ccr || 0;
        d15Sum += forecast.d15 || 0;
        evap370Sum += forecast.evap370 || 0;
        flowRateSum += forecast.flowRate || 0;
        h2HCSum += forecast.h2HC || 0;
        maxWABTSum += forecast.maxWABT || 0;
        metalsSum += forecast.metals || 0;
        nvalueSum += forecast.nvalue || 0;
        ppH2Sum += forecast.ppH2 || 0;
        svalueSum += forecast.svalue || 0;
        t50Sum += forecast.t50 || 0;
        tlpSulfurTargetSum += forecast.tlpSulfurTarget || 0;
      });

      const avgForecast: ForecastParameters = {
        catalystVolume: catVolSum / count,
        ccr: ccrSum / count,
        d15: d15Sum / count,
        duration: fp[0].duration,
        evap370: evap370Sum / count,
        flowRate: flowRateSum / count,
        h2HC: h2HCSum / count,
        maxWABT: maxWABTSum / count,
        metals: metalsSum / count,
        nvalue: nvalueSum / count,
        ppH2: ppH2Sum / count,
        svalue: svalueSum / count,
        t50: t50Sum / count,
        tlpSulfurTarget: tlpSulfurTargetSum / count,
        period: Number(period),
      };
      forecastParameters.push(avgForecast);
    });
    return forecastParameters;
  }
}
