<div class="card">
  <div class="d-flex flex-column card-body justify-content-between p-sm-4">
    <span class="h4">Mean activities data</span>
    <div class="d-flex flex-column">
      <app-filter-forecast-activity (intervalChanged)="interval = $event" (sorSelected)="getDpDate($event)"
        [lastDate]="lastDate">
      </app-filter-forecast-activity>

      <div class="d-flex flex-row flex-wrap gap-5 p-sm-4">
        <ng-container *ngIf="displayedForecastedStates; then forecastedStatesTableTemplate"></ng-container>
      </div>

    </div>
  </div>
</div>



<ng-template #forecastedStatesTableTemplate let-displayedForecastedStates>
  <div class="flex-column flex-grow-1">
    <div class="text-body-xs fw-bold">Forecast activities data</div>
    <table class="table" aria-describedby="forecasted-states-table" id="forecasted-states-table">
      <thead>
        <tr class="header-forecast text-body-s">
          <th scope="col">Date</th>
          <th scope="col">Days On Stream</th>
          <th scope="col">Period</th>
          <th scope="col">{{paramConfig?.forecast?.changeOutOptions?.mocMainCat?.tableHeaderName}} ({{paramConfig?.forecast?.changeOutOptions?.mocMainCat?.unit || '-'}})</th>
          <th scope="col">{{paramConfig?.forecast?.changeOutOptions?.mocDemetCat?.tableHeaderName}} ({{paramConfig?.forecast?.changeOutOptions?.mocDemetCat?.unit || '-'}})</th>
          <th scope="col">{{paramConfig?.forecast?.changeOutOptions?.cokeBuildUp?.tableHeaderName}} ({{paramConfig?.forecast?.changeOutOptions?.cokeBuildUp?.unit || '-'}})</th>
          <th scope="col">{{paramConfig?.catalyst?.conv?.tableHeaderName}} ({{paramConfig?.catalyst?.conv?.unit || '-'}})</th>
          <th scope="col">{{paramConfig?.catalyst?.hds?.tableHeaderName}} ({{paramConfig?.catalyst?.hds?.unit || '-'}})</th>
          <th scope="col">{{paramConfig?.catalyst?.hdn?.tableHeaderName}} ({{paramConfig?.catalyst?.hdn?.unit || '-'}})</th>
          <th scope="col">{{paramConfig?.forecast?.maxWABT?.tableHeaderName}} ({{paramConfig?.forecast?.maxWABT?.unit || '-'}})</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let forecast of displayedForecastedStates | periodicForecastStatesFilter: interval ; index as index "
          id="forecasted-state-row-{{ index }}">
          <td class="text-body-xs">{{ forecast?.date }}</td>
          <td class="text-body-xs">{{ forecast?.dos }}</td>
          <td *ngIf="index !== 0" class="text-body-xs">{{ addPeriodToForecast(forecast.dos) }}</td>
          <td *ngIf="index === 0"></td>
          <td class="text-body-xs">{{ forecast?.moc_main_cat | number: '0.2-3' }}</td>
          <td class="text-body-xs">{{ forecast?.moc_demet_cat | number: '0.2-3' }}</td>
          <td class="text-body-xs">{{ forecast?.coke | number: '0.2-3' }}</td>
          <td class="text-body-xs">{{ forecast?.kact_conv | number: '0.2-3' }}</td>
          <td class="text-body-xs">{{ forecast?.kact_hds | number: '0.2-3' }}</td>
          <td class="text-body-xs">{{ forecast?.kact_hdn | number: '0.2-3' }}</td>
          <td class="text-body-xs">{{ forecast?.wabt | number: '0.2-3' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>
