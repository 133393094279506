export const forecastKeyMap: { [oldKey: string]: string } = {
  'Catalyst ChangeOut - Duration Days': 'durationInDays',
  'Catalyst ChangeOut - MOC (demet cat) %wt': 'mocDemetCat',
  'Catalyst ChangeOut - MOC (main cat) %wt': 'mocMainCat',
  'Catalyst ChangeOut - Coke buildup (main cat)': 'cokeBuildUp',
  'Duration Days': 'duration',
  'D15 kg/l': 'd15',
  'S %wt': 'svalue',
  'CCR %wt': 'ccr',
  'N ppm': 'nvalue',
  'T50 °C': 't50',
  'Evap370 %wt': 'evap370',
  'Metals ppm': 'metals',
  'Flow rate t/h': 'flowRate',
  'ppH2 bar': 'ppH2',
  'H2/HC Nm3/m3': 'h2HC',
  'Catalyst volume m3': 'catalystVolume',
  'Max WABT °C': 'maxWABT',
  'TLP sulfur target PPM in TLP': 'tlpSulfurTarget',
};

export const catalystKeyMap: { [oldKey: string]: string } = {
  'Initial Activity': 'act',
  'Coke Deactivation Factor': 'absC',
  'Metal Deactivation Factor': 'absM',
};
