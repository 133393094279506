import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GraphDisplayService {
  private order: { [index: number]: number } = { 0: 0, 1: 1, 2: 3, 3: 4, 4: 0, 5: 0 };
  private displayOrder: BehaviorSubject<{ [index: number]: number }> = new BehaviorSubject(this.order);

  /**
   * @function setGraphDisplayOrder it set the graph display order
   * @param graphIndex graph index
   * @param graphDisplayOrder order to display the graph (1,2,3,4)
   */
  setGraphDisplayOrder(graphIndex: number, graphDisplayOrder: number) {
    this.order[graphIndex] = graphDisplayOrder;
    this.displayOrder.next(this.order);
  }

  getGraphDisplayOrder() {
    return this.displayOrder.getValue();
  }
}
