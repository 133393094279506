export type DialogType = 'error' | 'info' | 'success';

export class DialogData {
  title = '';
  message = '';
  type?: DialogType = 'info';
  okText?: string = 'OK';
  confirmationButtonIcon?: string = '';
  confirmationButtonColor?: 'primary' | 'accent' | 'warn' = 'primary';
}
