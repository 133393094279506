import { Injectable } from '@angular/core';
import { catalystKeyMap, forecastKeyMap } from '../../shared/constants/import-output-object-keys';

import { DOS, ForecastResults, ForecastToDisplay, IGraphData } from 'src/app/types/forecast-results';
import { CatalystParameters, IForecast } from 'src/app/types/forecast';
import { ParamConfigService } from 'src/app/shared/services/site-config/param-config.service';

@Injectable({
  providedIn: 'root',
})
export class ForecastMapperService {
  constructor(    
    private paramConfigService: ParamConfigService
  ) { }
  /**
   * @function mapData return data to pass them as params to the graph engine to create graphs
   * @param jsonData ForecastResults: resut of the call API
   * @returns object of 2 arrays {dos,forecastToDisplay}
   */
  mapData(jsonData: ForecastResults): IGraphData {
    const paramsConfig = this.paramConfigService.getParamsConfig();

    const dos: DOS = { title: 'Days on stream', values: [] };
    const hdn: ForecastToDisplay = { title: paramsConfig.catalyst?.hdn.graphDropdownName, uom: '', forecastedStates: [], testRuns: [] };
    const hds: ForecastToDisplay = { title: paramsConfig.catalyst?.hds.graphDropdownName, uom: '', forecastedStates: [], testRuns: [] };
    const conv: ForecastToDisplay = { title: paramsConfig.catalyst?.conv.graphDropdownName, uom: '', forecastedStates: [], testRuns: [] };
    const coke: ForecastToDisplay = { title: paramsConfig.forecast?.changeOutOptions.cokeBuildUp.graphDropdownName, uom: paramsConfig.forecast?.changeOutOptions.cokeBuildUp.unit, forecastedStates: [], testRuns: [] };
    const moc_main_catalyst: ForecastToDisplay = { title: paramsConfig.forecast?.changeOutOptions.mocMainCat.graphDropdownName, uom: paramsConfig.forecast?.changeOutOptions.mocMainCat.unit, forecastedStates: [], testRuns: [] };
    const moc_demet_catalyst: ForecastToDisplay = { title: paramsConfig.forecast?.changeOutOptions.mocDemetCat.graphDropdownName, uom: paramsConfig.forecast?.changeOutOptions.mocDemetCat.unit, forecastedStates: [], testRuns: [] };
    const wabt: ForecastToDisplay = { title: paramsConfig.forecast?.maxWABT.tableHeaderName, uom: paramsConfig.forecast?.maxWABT.unit, forecastedStates: [], testRuns: [] };

    if (jsonData) {
      for (const state of jsonData.forecastedStates) {
        dos.values.push(state.dos);
        hdn.forecastedStates.push(state.kact_hdn);
        hds.forecastedStates.push(state.kact_hds);
        conv.forecastedStates.push(state.kact_conv);
        coke.forecastedStates.push(state.coke);
        moc_main_catalyst.forecastedStates.push(state.moc_main_cat);
        moc_demet_catalyst.forecastedStates.push(state.moc_demet_cat);
        wabt.forecastedStates.push(state.wabt);
      }

      for (const run of jsonData.testRuns) {
        hdn.testRuns.push(run.main_catalyst.kact_hdn);
        hds.testRuns.push(run.main_catalyst.kact_hds);
        conv.testRuns.push(run.main_catalyst.kact_conv);
        coke.testRuns.push(run.main_catalyst.coke);
        moc_main_catalyst.testRuns.push(run.main_catalyst.moc);
        moc_demet_catalyst.testRuns.push(run.demet_catalyst.moc);
      }

      for (const input of jsonData.testInputDeact) {    
        wabt.testRuns.push(input.wabt);
      }
    }
    return {
      dos: dos,
      forecastToDisplay: [hds, hdn, conv, coke, moc_main_catalyst, moc_demet_catalyst, wabt],
    };
  }

  /**
   * @function mapImportedData it takes json data (transformed to json after import) and return forecast data (forecast parameters and catalyst parameters)
   * @param forecastInputFromImport
   * @returns IForecast
   */
  mapImportedData(forecastInputFromImport: any): IForecast {
    const forecastParameters: any = [];
    const periods = this.createPeriodsObjects(forecastInputFromImport);
    const catalystParameters = this.createCatalystObjects(forecastInputFromImport.CATALYST_PARAMETERS);
    const cleanperiodsObjects = this.removeNullObjects(periods.simulCOP);
    for (const period of cleanperiodsObjects) {
      const updatedObject = Object.fromEntries(Object.entries(period).map(([key, value]) => [forecastKeyMap[key], value]));
      const dataAfterGroupChangeOutOptions = this.groupChangeOutOptions(updatedObject);
      forecastParameters.push(dataAfterGroupChangeOutOptions);
    }
    return {
      cc: catalystParameters,
      simulCOP: forecastParameters,
    };
  }

  /**
   * @function createPeriodsObjects it takes object of FORECAST_PARAMETERS created by the import file and transform it to a FORECAST_PARAMETERS based on periods
   * @param object
   * @returns object of periods
   */
  private createPeriodsObjects(object: any) {
    const key = Object.keys(object.FORECAST_PARAMETERS[0]);
    const transformedData: any = { simulCOP: [] };
    for (let i = 1; i < key.length; i++) {
      const period: any = {};
      object.FORECAST_PARAMETERS.forEach((dataObj: any) => {
        period[dataObj['Parameters']] = dataObj[`Period ${i}`];
      });
      transformedData['simulCOP'].push(period);
    }
    return transformedData;
  }

  /**
   * @function removeNullObjects it takes the transformed data (array of periods) and remove all empty periods
   * @param periods
   * @returns object of periods
   */
  private removeNullObjects(periods: any) {
    return periods.filter((object: any) => {
      return Object.values(object).some((value) => value !== null);
    });
  }

  /**
   * @function groupChangeOutOptions it takes one period and group 'durationInDays', 'mocDemetCat', 'mocMainCat', 'cokeBuildUp' into one object changeOutOptions or create an object changeOutOptions and give it null value if 'durationInDays', 'mocDemetCat', 'mocMainCat', 'cokeBuildUp' are null
   * @param period
   * @returns
   */
  private groupChangeOutOptions(period: any) {
    const changeOutOptions = Object.entries(period)
      .filter(([key]) => ['durationInDays', 'mocDemetCat', 'mocMainCat', 'cokeBuildUp'].includes(key))
      .reduce((acc: any, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    const modifiedObj = {
      ...Object.fromEntries(Object.entries(period).filter(([key]) => !Object.hasOwn(changeOutOptions, key))),
      changeOutOptions: Object.values(changeOutOptions).some((value) => value !== null) ? changeOutOptions : null,
    };

    return modifiedObj;
  }

  /**
   * @function createCatalystObjects it takes a catalyst params in excel format and convert them to ICatalystParameters format
   * @param data
   * @returns ICatalystParameters object
   */
  private createCatalystObjects(data: { Parameters: string; '370+ Conversion': number; HDS: number; HDN: number }[]): CatalystParameters {
    const transformedObject: any = {};
    for (const item of data) {
      const newKey = catalystKeyMap[item.Parameters];
      transformedObject[newKey] = {
        conv: item['370+ Conversion'],
        hds: item.HDS,
        hdn: item.HDN,
      };
    }

    return transformedObject;
  }
}
