import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../../services/modal/modal.config';

@Component({
  selector: 'app-error-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  mainMessageIcon = 'info';
  okText = 'OK';

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    if (data.type === 'success') {
      this.mainMessageIcon = 'check_circle';
    } else if (data.type === 'info') {
      this.mainMessageIcon = 'info';
    } else {
      this.mainMessageIcon = 'error';
    }

    this.okText = data.okText ?? this.okText;
  }
}
