<nav class="navbar navbar-expand-sm flex-column">
  <div class="container-fluid d-flex w-100 header-with-tabs-for-desktop">
    <!-- TotalEnergies Logo -->
    <div class="w-100">
      <div class="container-fluid d-flex pt-2 pe-0">
        <!-- Application Name -->
        <span class="navbar-app-name d-flex align-items-center">Catwin</span>
        <span class="material-symbols-outlined align-self-center ms-1" role="button" (keydown)="openCatwinInfo()" (click)="openCatwinInfo()">error</span>
        <ul class="nav nav-tabs ms-5 align-items-end">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              data-bs-toggle="tab"
              data-bs-target="#forecast"
              type="button"
              role="tab"
              aria-controls="forecast"
              aria-selected="true"
              routerLink="/forecast"
            >
              Catalyst simulations
            </button>
          </li>
        </ul>
        <!-- Navbar content -->
        <ul class="navbar-nav ms-auto d-flex flex-row align-items-center">          

          <li class="nav-item d-xl-block" *ngIf="refineries.length > 0">
            <div class="dropdown ms-4">
              <div class="d-flex">                
                <button class="btn dropdown-toggle" type="button" id="refinery-names" data-bs-toggle="dropdown" aria-expanded="false">
                  {{selectedRefinery?.label}}
                </button>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="refinery-names">
                  <li *ngFor="let refinery of refineries" role="button" (keydown)="onRefinerySelection(refinery)"><a class="dropdown-item" (click)="onRefinerySelection(refinery)">{{refinery.label}}</a></li>
                </ul>
              </div>
            </div>
          </li>

          <li class="nav-item ms-md-4">
            <span class="navbar-text text-uppercase">{{ username }}</span>
            <button
              class="btn btn-outline-primary btn-circle"
              [matMenuTriggerFor]="menu"
            >
              <span class="material-icons-outlined">account_circle</span>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="logout()">Log out</button>
            </mat-menu>
          </li>
          <li class="nav-item d-none d-lg-block">
            <a class="navbar-brand text-primary" href="#">
              <img src="assets/logo/logo-TotalEnergies.svg" alt="" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<div class="container-fluid">
  <!-- Tab content -->
  <div class="tab-content" id="navbarTabContent">
    <router-outlet></router-outlet>    
  </div>
</div>
