import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogData } from './modal.config';
import { ModalComponent } from '../../components/modal/info-modal/modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(public matDialog: MatDialog) {}

  showErrorModal(errorMsg: DialogData): void {
    this.matDialog.open(ModalComponent, {
      panelClass: 'dialog-generic-container',
      data: errorMsg,
    });
  }
}
