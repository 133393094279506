<div id="parameters-{{ uniqId }}">
  <div class="accordion">
    <div class="accordion-item">
      <h4 class="accordion-header" id="panelsStayOpen-headingThree">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          id="accordion-button-{{ uniqId }}"
          [attr.data-bs-target]="'#panelsStayOpen-collapseThree-' + uniqId"
          aria-expanded="false"
          aria-controls="panelsStayOpen-collapseThree"
        >
          <ng-content select="[title]"></ng-content>
          <div class="additional-details" data-bs-toggle="collapse" data-bs-target>
            <ng-content select="[additional-details]"></ng-content>
          </div>
        </button>
      </h4>
      <div id="panelsStayOpen-collapseThree-{{ uniqId }}" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
        <div class="accordion-body">
          <ng-content select="[body]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
