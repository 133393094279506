import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { forecastKeyMap } from 'src/app/shared/constants/import-output-object-keys';
import {
  CatalystParameters,
  ForecastParameters,
  ICatalystParametersToExport,
  IForecast,
  IForecastCatalystParametersToExport,
  IForecastParametersToExport,
} from 'src/app/types/forecast';
import { FlattenedTestRun, ForecastResultToExport, ForecastResults, ForecastedState, ForecastedStatesToExport, TestRun } from 'src/app/types/forecast-results';
import { ParamConfigService } from 'src/app/shared/services/site-config/param-config.service';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  private inputSubject: BehaviorSubject<IForecast> = new BehaviorSubject<IForecast>(null as any);

  private outputSubject: BehaviorSubject<ForecastResults> = new BehaviorSubject<ForecastResults>(null as any);

  public paramConfig: any;
  constructor(    
    private paramConfigService: ParamConfigService
  ) { }

  /**
   * @function exportInputToExcel
   * @param forecastData array of forecast data IForecastParameters[]
   * @param catalystData catalyst data ICatalystParameters
   * @returns object of mapped forecast and catalyst data IForecastCatalystParametersToExport
   */
  exportInputToExcel(forecastData: ForecastParameters[], catalystData: CatalystParameters): IForecastCatalystParametersToExport {
    this.paramConfig = this.paramConfigService.getParamsConfig();
    const headers = ['Parameters', ...Array.from({ length: forecastData.length }, (_, i) => `Period ${i + 1}`)];
    const forecastSheetData = this.createForecastSheetData(forecastData);
    const transformedCatalystData = this.transformCatalystKeys(catalystData);
    const catalystHeaders = ['Parameters', ...Object.keys(transformedCatalystData['Initial Activity'])];
    const catalystSheetData = this.createCatalystSheetData(transformedCatalystData);
    return {
      forecast: { header: headers, values: forecastSheetData },
      catalyst: { header: catalystHeaders, values: catalystSheetData },
    };
  }
  /**
   * @function exportOutputToExcel
   * @param forecast array of forecastedStates object
   * @param testRuns testRuns
   * @returns object of forecastResult(forecastedStates and testRuns) ForecastResultToExport
   */
  exportOutputToExcel(forecast: ForecastedState[], testRuns: TestRun[]): ForecastResultToExport {
    const forecastHeader = ['dos'];
    const testRuntHeader = ['dos'];
    // add DOS to test run that mathch ovject in forecsat
    testRuns.forEach((value: any, key: any) => {
      value.dos = forecast[key].dos;
    });
    const transformedTestRun = this.flattenTestRun(testRuns);

    return {
      forecastedStates: { header: forecastHeader, values: forecast },
      testRuns: { header: testRuntHeader, values: transformedTestRun },
    };
  }

  /**
   * @function exportFlowerToExcel
   * @param forecast array of forecastedStates object
   * @param testRuns testRuns
   * @returns object of forecastResult(forecastedStates and testRuns) ForecastResultToExport
   */
  exportFlowersToExcel(formattedData:ForecastParameters[]) : ForecastedStatesToExport {
    this.paramConfig = this.paramConfigService.getParamsConfig();
    const headers = ['Parameters', ...Array.from({ length: formattedData.length }, (_, i) => formattedData[i].date ?? '')];    
    const flowerSheetData = this.createFlowersSheetData(formattedData);    
    return { header: headers, values: flowerSheetData };
  }

  /**
   * @function createFlowersSheetData
   * @param flowerArray array of flower data IForecastParameters[]
   * @returns array of flower parameters in row (excel sheet format)
   */
  private createFlowersSheetData(flowerArray: ForecastParameters[]): any[] { 
    const configForecastKeyMap = this.getForecastKeyMapFromConfig();   
    const newFlowerArray = this.transformForecastKeys(flowerArray);    
    const allKeys = newFlowerArray.reduce((keys: any, item: any) => {      
      return keys.concat(Object.keys(item));
    }, []);
    
    const uniqueKeys = Array.from(new Set(allKeys)).sort((a: any, b: any) => a - b).filter(key => key !== 'date');
    uniqueKeys.sort((a:any, b:any) => Object.keys(configForecastKeyMap).indexOf(a) - Object.keys(configForecastKeyMap).indexOf(b));
    
    return uniqueKeys.map((key: any) => {      
      const rowData: any = {
        Parameters: key,
      };
      newFlowerArray.forEach((item: any) => {        
        rowData[item.date] = item[key];
      });
      return rowData;         
    });
  }

  /**
   * @function createCatalystSheetData
   * @param transformedCatalystData
   * @returns object of catalyst in row (excel sheet format)
   */
  private createCatalystSheetData(transformedCatalystData: any) {
    return Object.keys(transformedCatalystData).map((key) => {
      const rowData: any = {
        Parameters: key,
      };
      Object.keys(transformedCatalystData[key]).forEach((subKey) => {
        rowData[subKey] = transformedCatalystData[key][subKey];
      });
      return rowData;
    });
  }

  /**
   * @function createForecastSheetData
   * @param forecastArray array of forecast data IForecastParameters[]
   * @returns array of forecast parameters in row (excel sheet format)
   */
  private createForecastSheetData(forecastArray: ForecastParameters[]): any[] {
    const newForecastArray = this.transformForecastKeys(forecastArray);
    const allKeys = newForecastArray.reduce((keys: any, item: any) => {
      return keys.concat(Object.keys(item));
    }, []);
    const uniqueKeys = Array.from(new Set(allKeys)).sort((a: any, b: any) => a - b);
    return uniqueKeys.map((key: any) => {
      const rowData: any = {
        Parameters: key,
      };
      newForecastArray.forEach((item: any, index: any) => {
        rowData[`Period ${index + 1}`] = item[key];
      });
      return rowData;
    });
  }

  /**
   * @function transformForecastKeys
   * @param forecastArray array of forecast data IForecastParameters[]
   * @returns array of object of forecast parametrs with new keys that match value in forecastKeyMap
   */
  private transformForecastKeys(forecastArray: ForecastParameters[]): IForecastParametersToExport[] {
    const newForecastArray: any = [];
    const configForecastKeyMap = this.getForecastKeyMapFromConfig();
        
    forecastArray.forEach((forecast: any) => {
      const flattedForecast = this.flattenForecast(forecast);
      const newForecast: any = {};
      for (const element in flattedForecast) {        
        if(Object.hasOwn(flattedForecast, element) && element === 'date') {
          newForecast[element] = flattedForecast[element];
        }
        else if(Object.hasOwn(flattedForecast, element)){
          for (const keyMap in configForecastKeyMap) {
            if (Object.hasOwn(configForecastKeyMap, keyMap) && element === configForecastKeyMap[keyMap]) {                
              newForecast[keyMap] = flattedForecast[element];                
            }
          }
        }        
      }
      newForecastArray.push(newForecast);
    });
    return newForecastArray;
  }

  /**
   * @function flattenForecast
   * @param forecast IForecastParameters
   * @returns flatted forecast object
   */
  private flattenForecast(forecast: ForecastParameters): any {
    const flattenedForecast = { ...forecast };
    if (forecast.changeOutOptions !== null) {
      Object.assign(flattenedForecast, forecast.changeOutOptions);
      delete flattenedForecast.changeOutOptions;
    }
    return flattenedForecast;
  }

  /**
   * @function transformCatalystKeys
   * @param catalyst object of catalyst parameters ICatalystParameters
   * @returns catalyst parametres ICatalystParametersToExport in rows (excel sheet format )
   */
  private transformCatalystKeys(catalyst: CatalystParameters): ICatalystParametersToExport {
    //const paramConfig = this.paramConfigService.getParamsConfig();
    const transformedObj: any = {};
    for (const [key, value] of Object.entries(catalyst)) {
      const newKey = key.replace('act', 'Initial Activity').replace('absC', 'Coke Deactivation Factor').replace('absM', 'Metal Deactivation Factor');
      const innerObj: any = {};
      for (const [innerKey, innerValue] of Object.entries(value)) {
        let newInnerKey = '';
        switch (innerKey) {
          case 'conv':
            newInnerKey = this.paramConfig?.catalyst?.conv?.name;
            break;
          case 'hds':
            newInnerKey = this.paramConfig?.catalyst?.hds?.name;
            break;
          case 'hdn':
            newInnerKey = this.paramConfig?.catalyst?.hdn?.name;
            break;
        }
        innerObj[newInnerKey] = innerValue;
      }
      transformedObj[newKey] = innerObj;
    }
    return transformedObj;
  }

  /**
   * @function flattenTestRun
   * @param testRuns array of testRun object
   * @returns FlattenedTestRun[] array of flattened test runs
   */
  private flattenTestRun(testRuns: any): FlattenedTestRun[] {
    const flattenedTestRunObjects: FlattenedTestRun[] = [];
    for (const testRun of testRuns) {
      const flattenedTestRunObject: FlattenedTestRun = {
        demet_catalyst_moc: testRun.demet_catalyst.moc,
        demet_catalyst_ccr: testRun.demet_catalyst.ccr,
        demet_catalyst_metals: testRun.demet_catalyst.metals,
        main_catalyst_kact_conv: testRun.main_catalyst.kact_conv,
        main_catalyst_kact_hds: testRun.main_catalyst.kact_hds,
        main_catalyst_kact_hdn: testRun.main_catalyst.kact_hdn,
        main_catalyst_moc: testRun.main_catalyst.moc,
        main_catalyst_coke: testRun.main_catalyst.coke,
        main_catalyst_metals: testRun.main_catalyst.metals,
        time: testRun.time,
        dos: testRun.dos,
      };

      flattenedTestRunObjects.push(flattenedTestRunObject);
    }

    return flattenedTestRunObjects;
  }

  /**
   * @function setInputForExport
   * @param input IForecast
   */
  setInputForExport(input: IForecast) {
    this.inputSubject.next(input);
  }

  /**
   * @function getInputForExport
   * @returns input
   */
  getInputForExport() {
    return this.inputSubject.getValue();
  }

  /**
   * @function setOutputForExport
   * @param output ForecastResults
   */
  setOutputForExport(output: ForecastResults) {
    this.outputSubject.next(output);
  }

  /**
   * @function getOutputForExport
   * @returns output result
   */
  getOutputForExport() {
    return this.outputSubject.getValue();
  }

  getForecastKeyMapFromConfig() {    
    const keyMap:{[oldKey: string]: string;} = {};
    
    for (const value of Object.values(forecastKeyMap)) {
      const configKeyMap = this.paramConfig.forecast.changeOutOptions[value] ? 'Catalyst ChangeOut - ' + this.paramConfig?.forecast?.changeOutOptions[value]?.name + ' ' + this.paramConfig?.forecast?.changeOutOptions[value]?.unit : 
        this.paramConfig?.forecast[value]?.name + ' ' + this.paramConfig?.forecast[value]?.unit; 
      keyMap[configKeyMap.trim()] = value;      
    }
    return keyMap;
  }

  columnWidth(header?:string[]) {
    const cols:any[] = [];
    if(header) {      
      header.forEach(() => {
        cols.push({ wpx: 100 });
      });
    }
    return {
      inputFirstColumnWidth: [{ wpx: 240 }],
      flowerColumnWidth: cols,
      forecastColumnWidth: [
        { wpx: 60 },
        { wpx: 60 },
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 120 },
      ],
      testRunColumnWidth: [
        { wpx: 60 },
        { wpx: 126 },
        { wpx: 126 },
        { wpx: 126 },
        { wpx: 130 },
        { wpx: 126 },
        { wpx: 126 },
        { wpx: 126 },
        { wpx: 126 },
        { wpx: 126 },
        { wpx: 60 },
      ],
    };
  }
}
