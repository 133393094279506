import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IForecast } from 'src/app/types/forecast';
import { ForecastResults, IGraphMarkData } from 'src/app/types/forecast-results';

@Injectable({
  providedIn: 'root',
})
export class ForecastDataPeriodsService {
  private graphMarkData: BehaviorSubject<any> = new BehaviorSubject(null);

  private graphPeriodsDuration: { [key: number]: { value: number; name: string } } = {};

  setPeriodDuration(periodDuration: { name: string; value: number; catalystChangeOut?: number }[]) {
    this.graphPeriodsDuration = {};
    periodDuration.forEach((duration, index) => {
      this.graphPeriodsDuration[index] = {
        name: duration.name,
        value: duration.value,
      };
    });
  }

  /**
   * @function setGraphMarkData set a graph periods start and end
   * @param graphPeriodMarks
   */
  setGraphMarkData(graphPeriodMarks: IGraphMarkData | null) {
    if (graphPeriodMarks) this.graphMarkData.next(graphPeriodMarks);
  }

  /**
   * @function computeGraphPeriods
   * @param durations
   * @param lastDayOnStreamInTestRuns
   * @param lastForecastedStatesDay
   * @returns { val: number; name: string }[]
   */

  computeGraphPeriods(
    durations: { [key: number]: { value: number; name: string } },
    lastDayOnStreamInTestRuns: number,
    lastForecastedStatesDay: number,
  ): { val: number; name: string }[] {
    const firstForecastDay = lastDayOnStreamInTestRuns + 1;
    const lastForecastDay = lastForecastedStatesDay;
    const graphPeriods: { val: number; name: string }[] = [];
    let inter: number = firstForecastDay;

    if (durations[0]) {
      graphPeriods.push({ val: inter, name: durations[0].name });
    } else {
      graphPeriods.push({ val: inter, name: 'undefined' });
    }

    for (const key in durations) {
      const duration = durations[Number(key)];
      const nextPeriod = duration.value + inter;

      if (nextPeriod > lastForecastDay) {
        graphPeriods.push({ val: lastForecastDay, name: durations[Number(key)].name });
        return graphPeriods;
      }

      graphPeriods.push({ val: nextPeriod, name: durations[Number(key)].name });
      inter = nextPeriod;
    }

    return graphPeriods;
  }

  /**
   * @function createPeriodMarker
   * @param lastDayOnStreamInTestRuns
   * @param lastForecastedStatesDay
   * @returns IGraphMarkData
   */
  createPeriodMarker(lastDayOnStreamInTestRuns: number, lastForecastedStatesDay: number): IGraphMarkData {
    let graphPeriodMarks;
    let graphAreaMark;
    if (this.graphPeriodsDuration) {
      const data = this.computeGraphPeriods(this.graphPeriodsDuration, lastDayOnStreamInTestRuns, lastForecastedStatesDay);
      const areas = this.createPeriodsInterval(data, lastForecastedStatesDay);
      graphAreaMark = this.removeChangeOut(areas);
      graphPeriodMarks = { markline: this.createArrayOfObjectsmark(data), firstMarkArea: lastDayOnStreamInTestRuns + 1, lastMarkArea: lastForecastedStatesDay };
    }
    return { graphPeriodMarks: graphPeriodMarks, graphAreaMark: graphAreaMark };
  }

  /**
   * @function createArrayOfObjectsmark
   * @param newObj
   * @returns { xAxis: number;}[]
   */
  createArrayOfObjectsmark(arr: { val: number; name: string }[]): { xAxis: number }[] {
    const result: { xAxis: number }[] = [];
    for (const key of arr) {
      result.push({ xAxis: key.val });
    }
    return result;
  }

  /**
   * @function getGraphPeriodObject returns an array of periods and catalystChangeOut if exist
   * @param inputs IForecast
   * @returns { name: string; value: number; catalystChangeOut?: number }[]
   */
  getGraphPeriodObject(inputs: IForecast): { value: number; name: string; catalystChangeOut?: number }[] {
    const result: { value: number; name: string }[] = [];
    if (inputs) {
      inputs.simulCOP.forEach((period, index) => {
        if (period.changeOutOptions) result.push({ name: 'changeOut', value: Number(period.changeOutOptions.durationInDays) });
        result.push({ name: `Period ${index}`, value: Number(period.duration) });
      });
    }

    return result;
  }

  /**
   * @function createPeriodsInterval
   * @param periods periods
   * @param last last forecast day
   * @returns interval of periods
   */
  createPeriodsInterval(periods: { val: number; name: string }[], last: number): { xAxis: number; name: string }[][] {
    const interval: { xAxis: number; name: string }[][] = [];
    for (let i = 0; i < periods.length - 1; i++) {
      const subArray = [
        {
          xAxis: periods[i].val,
          name: periods[i].name,
        },
        {
          xAxis: periods[i + 2] ? periods[i + 1].val : last,
          name: periods[i + 1].name,
        },
      ];
      interval.push(subArray);
    }

    return interval;
  }

  /**
   * @function removeChangeOut that removes catalyst changeOut period from the interval of periods
   * @param arr interval of periods
   * @returns {xAxis: number}[][]
   */
  removeChangeOut(arr: { xAxis: number; name?: string }[][]): { xAxis: number }[][] {
    const periods = JSON.parse(JSON.stringify(arr));

    for (let i = 0; i < periods.length; i++) {
      const current = periods[i];
      const next = periods[i + 1];
      if (current[1].name === 'changeOut' && next[0].name === 'changeOut' && current[1].xAxis === next[0].xAxis) {
        periods.splice(i, 1);
      }
      delete periods[i][0].name;
      delete periods[i][1].name;
    }

    return periods;
  }

  /**
   * @function getPeriodMarkers
   * @returns periods markers
   */
  getPeriodMarkers() {
    return this.graphMarkData?.getValue();
  }

  /**
   * @function getLastDayOnStreamInTestRuns
   * @param forecastResult
   * @returns number
   */
  getLastDayOnStreamInTestRuns(forecastResult: ForecastResults | undefined): number {
    return forecastResult?.testRuns.map((value: any) => value.time).reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
  }
}
