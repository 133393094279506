import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private hasRequestInProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  getRequestProgressObservable() {
    return this.hasRequestInProgress.asObservable();
  }

  setRequestProgress(value: boolean) {
    this.hasRequestInProgress.next(value);
  }

}
