import { Pipe, PipeTransform } from '@angular/core';
import { ForecastedState } from '../../types/forecast-results';

@Pipe({
  name: 'periodicForecastStatesFilter',
})
/**
 * Filter out the states whose DOS (= days on stream) is not
 * in the list [startingDate, startingDate + interval, startingDate + 2* interval, ...]
 */
export class FilterPeriodicForecastStatesPipe implements PipeTransform {
  transform(forecastStates: ForecastedState[], interval?: number): ForecastedState[] {
    if (!forecastStates || !interval || forecastStates.length == 0) {
      return forecastStates;
    }

    const startingDaysOnStream = forecastStates[0].dos; // assuming forecasts are sorted by "dos"
    return forecastStates.filter((element) => element.dos - startingDaysOnStream >= 0 && (element.dos - startingDaysOnStream) % interval == 0);
  }
}
