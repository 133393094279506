import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../security/authentication.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CatwinInfoComponent } from '../shared/components/modal/catwin-info/catwin-info.component';
import { REFINERY_CODE } from 'src/app/shared/constants/refinery-names';
import { HttpService } from 'src/app/shared/services/http.service';
import { ParamConfigService } from 'src/app/shared/services/site-config/param-config.service';
import { ENDPOINTS } from '../shared/constants/endpoints';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
})
export class HomepageComponent implements OnInit {
  public connectedUserSubscription = this.authenticationService.connectedUser;
  public username = 'Account name';
  
  public refineries: {code:string, label:string}[]= [];
  public selectedRefinery: { code: string; label: string; } | undefined;  
  
  public isGetSiteConfig:boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private matDialog: MatDialog,
    private httpService: HttpService,
    private paramConfigService: ParamConfigService
  ) {}

  ngOnInit(): void {    
    this.connectedUserSubscription.subscribe(connectedUser => {      
      this.username = connectedUser?.username ?? this.username;     

      this.refineries = Object.entries(REFINERY_CODE)
      .filter(([key]) =>  connectedUser?.roles?.includes(key))
      .reduce((acc: any, [key, value], index) => {
        acc[index] = {code: key, label: value};
        return acc;
      }, []);
      
      this.selectedRefinery = this.selectedRefinery ?? this.refineries[0];   
      if(this.selectedRefinery && !this.isGetSiteConfig) {        
        this.fetchParamsConfig();
        this.router.navigate([this.selectedRefinery?.code + '/forecast']);
      }                
    });    
  }

  public onRefinerySelection(refinery: {code:string, label:string}) {
    this.selectedRefinery = refinery;   
    this.fetchParamsConfig(); 
    this.router.navigate([refinery.code + '/forecast']);
  }

  public fetchParamsConfig() {
    this.isGetSiteConfig = true;  
    return this.httpService.getById(ENDPOINTS.parametersConfig, { paramName: 'type', id: 'UnibonUI' }).subscribe((config:any) => {      
      this.paramConfigService.setParamsConfig(JSON.parse(config.configurationData));
    });
  }

  public logout() {
    this.authenticationService.logout();
  }
  openCatwinInfo(): void {
    this.matDialog.open(CatwinInfoComponent, {
      panelClass: 'dialog-generic-container',
      width: '1000px',
      height: '540px',
    });
  }
}
