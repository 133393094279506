import { Component, Input, OnChanges } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { IForecastDisplayPreference } from 'src/app/types/forecast-display-preference';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'D MMMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-cycle-length',
  templateUrl: './cycle-length.component.html',
  styleUrls: ['./cycle-length.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CycleLengthComponent implements OnChanges {
  @Input()
  cycleLengthInDays = 0;

  @Input()
  cycleLengthDate = '';

  @Input()
  startRunDate?: IForecastDisplayPreference | null;
  public date!: moment.Moment;

  cycleLengthInMonths = 0;
  cycleLengthInYears = 0;
  ngOnChanges() {    
    if (this.startRunDate) this.date = moment(this.startRunDate.start_of_run);
    this.convertCycleLength();
  }

  convertCycleLength() {
    if (this.cycleLengthInDays && this.cycleLengthInDays > 0) {
      this.cycleLengthInMonths = Math.trunc(this.cycleLengthInDays / 30.5);
      this.cycleLengthInYears = this.cycleLengthInMonths / 12;
    }
  }
}
