
<div class="catwin-info" *ngIf="siteInfo">
    <div class="header">
        <div class="title">
            Catwin version
        </div>
        <div class="close">
            <span class="material-symbols-outlined" [mat-dialog-close]="false">
                close
            </span>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="body">
        <div class="process">
            <span class="title">
                Process model DLLs implemented on R4.0 are :
            </span>
            <ul>
                <ng-container *ngFor="let dll of siteInfo?.processModelDll">
                    <li>{{dll}}</li>  
                </ng-container>                             
            </ul>
        </div>
       <div class="automatic-test">
        <span class="title">
            Automatic test run integration configuration
        </span>
        <ul>
            <ng-container *ngFor="let testRunConfig of siteInfo?.autoTestRunConfiguration.testRun">
                <li>
                    {{testRunConfig}}
                </li>
            </ng-container>            
            <li>
                Consequently, the “Transfer” workflow has been configured as follows:
                <ul *ngFor="let workflowConfig of siteInfo?.autoTestRunConfiguration.tarnsferWorkflow">
                    <li>
                        {{workflowConfig}} 
                    </li>                    
                </ul>
            </li>

        </ul>
       </div>
       <div class="user-guide" (keydown)="downloadDocLink('user-guide')" (click)="downloadDocLink('user-guide')">
            <div>
                <span class="material-symbols-outlined">
                    arrow_forward
                </span> 
                <span class="label">User Guide</span>                
            </div>
            <div class="download"> 
                <span class="material-symbols-outlined">
                    download
                </span>
                Download .pdf
            </div>
       </div>
       <div class="glossary" (keydown)="downloadDocLink('glossary')" (click)="downloadDocLink('glossary')">
            <div>
                <span class="material-symbols-outlined">
                    arrow_forward
                </span> 
                <span class="label">Glossary</span>                
            </div>
            <div class="download"> 
                <span class="material-symbols-outlined">
                    download
                </span>
                Download .pdf
            </div>
        </div>
    </div>
</div>