import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { UiGraphComponent } from './components/ui-graph/ui-graph.component';
import { UiAccordionComponent } from './components/ui-accordion/ui-accordion.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { ModalComponent } from './components/modal/info-modal/modal.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { NumberToArrayPipe } from './pipes/number-to-array.pipe';
import { UiImportBtnComponent } from './components/ui-import-btn/ui-import-btn.component';
import { UiExportBtnComponent } from './components/ui-export-btn/ui-export-btn.component';
import { ConfirmationModalComponent } from './components/modal/confirmation-modal/confirmation-modal.component';
import { CatwinInfoComponent } from './components/modal/catwin-info/catwin-info.component';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [
    UiGraphComponent,
    UiAccordionComponent,
    ModalComponent,
    NumberToArrayPipe,
    UiImportBtnComponent,
    UiExportBtnComponent,
    ConfirmationModalComponent,
    CatwinInfoComponent,
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatDividerModule,
  ],
  exports: [
    MatMenuModule,
    UiGraphComponent,
    UiAccordionComponent,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NumberToArrayPipe,
    UiImportBtnComponent,
    UiExportBtnComponent,
    CatwinInfoComponent,
  ],
})
export class SharedModule {}
