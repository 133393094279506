import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { FlowersData } from 'src/app/types/flowers-data';
import { ForecastParameters } from 'src/app/types/forecast';

@Injectable({
  providedIn: 'root',
})
export class FlowerService { 

  formatFlowerData(flowersData:FlowersData[]) {    
    const formattedData: ForecastParameters[] = [];
    // group flowers data by day
    const flowersDataGroupedByDay = (<any>Object).groupBy(flowersData, (data: FlowersData) => {
      return data.day;
    });

    // order flowers data by day
    const orderedFlowersData = Object.keys(flowersDataGroupedByDay)
      .sort((a: any, b: any) => moment(a).diff(b))
      .reduce((obj: any, key: any) => {
        obj[key] = flowersDataGroupedByDay[key];
        return obj;
      }, {});

    // create formatted list
    for (const key of Object.keys(orderedFlowersData)) {
      const data: any = {};
      data.date = key;
      for (const val of orderedFlowersData[key]) {
        const name = val.parameterName;
        data[name] = val.value;
      }
      formattedData.push(data);
    }
    return formattedData;
  }
}
