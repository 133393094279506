import { Component } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { ParamConfigService } from 'src/app/shared/services/site-config/param-config.service';

@Component({
  selector: 'app-catalyst-parameters',
  templateUrl: './catalyst-parameters.component.html',
  styleUrls: ['./catalyst-parameters.component.scss'],
  viewProviders: [
    {
      provide:ControlContainer,
      useExisting:FormGroupDirective
    }
  ]
})
export class CatalystParametersComponent {
  public uniqId = 'catalyst';
  catalystParamConfig: any;

  constructor(    
    private paramConfigService: ParamConfigService
  ) {
    this.catalystParamConfig = this.paramConfigService.getParamsConfig().catalyst;    
  }

}
