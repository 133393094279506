<div class="login-background">
  <div class="login-card card">
      <div class="card-body d-flex flex-column align-items-center justify-content-around">
          <img src="assets/logo/logo-TotalEnergies.svg"
            alt="Logo TotalEnergies"
            class="logo"
            />
          <h1 class="card-title">Catwin</h1>
        <button
          class="btn btn-primary"
          type="button" role="tab"
          id="login-button"
          aria-controls="login" aria-selected="true"
          (click)=login()>
          Log in
        </button>
      </div>
  </div>
</div>