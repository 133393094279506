import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { filter } from 'rxjs/operators';
import { InteractionStatus } from '@azure/msal-browser';
import { AuthenticationService } from 'src/app/security/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard {
  constructor(
    private msalService: MsalService,
    private authService: AuthenticationService,
    private router: Router,
    private msalBroadcastService: MsalBroadcastService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.msalBroadcastService.inProgress$.pipe(
      filter((status) => status === InteractionStatus.None),
      switchMap(() => {
        if (state.url !== '/' && state.url !== '/login' && this.msalService.instance.getAllAccounts().length === 0) {
          this.authService.login(state.url);
          return of(true);
        }
        if (this.msalService.instance.getAllAccounts().length > 0) {
          return of(true);
        }

        this.router.navigate(['/login']);
        return of(false);
      }),
    );
  }
}
