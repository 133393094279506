import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
/**
 * @class
 * @constructor
 */
export class HttpService {
  private readonly url = `${environment.backendUrl}`;

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': environment.backendUrl,
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
    }),
  };

  constructor(private httpClient: HttpClient) {}

  /**
   *  send function to post data to the backend
   * @param data data to post
   * @param endpoint api endpoint
   * @returns Observable
   */
  send<T>(data: T, endpoint: string): Observable<any> {
    return this.httpClient.post<T>(`${this.url}${endpoint}`, data, this.httpOptions);
  }

  /**
   * http get method to get data from backend
   * @param endpoint api endpoint
   * @returns Observable
   */
  get<T>(endpoint: string): Observable<T> {
    return this.httpClient.get<T>(`${this.url}${endpoint}`, this.httpOptions);
  }

  /**
   * http get method to get data from backend
   * @param endpoint api endpoint
   * @param id request id
   * @returns Observable
   */
  getById<T>(endpoint: string, parameters: { paramName: string; id: number|string }): Observable<T> {
    const params = new HttpParams().set(parameters.paramName, parameters.id);
    const options = { ...this.httpOptions, params: params };
    return this.httpClient.get<T>(`${this.url}${endpoint}`, options);
  }
   
}
