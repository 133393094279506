import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForecastComponent } from './forecast.component';
import { ForecastRoutingModule } from './forecast-routing.module';
import { CatalystParametersComponent } from './catalyst-parameters/catalyst-parameters.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { CycleLengthComponent } from './cycle-length/cycle-length.component';
import { DetailsParametersComponent } from './details-parameters/details-parameters.component';
import { ForecastParametersComponent } from './forecast-parameters/forecast-parameters.component';
import { ForecastParametersOnePeriodComponent } from './forecast-parameters-one-period/forecast-parameters-one-period.component';
import { FilterForecastActivityComponent } from './details-parameters/filter-forecast-activity/filter-forecast-activity.component';
import { FilterPeriodicForecastStatesPipe } from './pipes/periodic-dos-filter-pipe';
import { ForecastGraphComponent } from './forecast-graph/forecast-graph.component';
import { FlowersDataModalComponent } from './flowers-data-modal/flowers-data-modal.component';
import { PeriodPickerComponent } from './flowers-data-modal/period-picker/period-picker.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { DefaultMatCalendarRangeStrategy, MatRangeDateSelectionModel } from '@angular/material/datepicker';

@NgModule({
  declarations: [
    ForecastComponent,
    CatalystParametersComponent,
    CycleLengthComponent,
    DetailsParametersComponent,
    ForecastParametersComponent,
    ForecastParametersOnePeriodComponent,
    FilterForecastActivityComponent,
    FilterPeriodicForecastStatesPipe,
    ForecastGraphComponent,
    FlowersDataModalComponent,
    PeriodPickerComponent,
  ],
  imports: [CommonModule, SharedModule, ReactiveFormsModule, ForecastRoutingModule, FormsModule, MatChipsModule, MatChipsModule, MatCardModule],
  providers: [DefaultMatCalendarRangeStrategy, MatRangeDateSelectionModel],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ForecastModule {}
