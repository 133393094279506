import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { ForecastResults, ForecastedState, TestRun } from '../../types/forecast-results';
import * as moment from 'moment';
import { ForecastDisplayPreferencesService } from '../services/forecast-display-preferences.service';
import { Subscription } from 'rxjs';
import { IForecastDisplayPreference } from 'src/app/types/forecast-display-preference';
import { ForecastDataPeriodsService } from '../services/forecast-data-periods.service';
import { ParamConfigService } from 'src/app/shared/services/site-config/param-config.service';

@Component({
  selector: 'app-details-parameters',
  templateUrl: './details-parameters.component.html',
  styleUrls: ['./details-parameters.component.scss'],
})
export class DetailsParametersComponent implements OnChanges, OnDestroy {
  @Input()
  forecastResults?: ForecastResults;

  @Input()
  periodDuration?: number[];

  @Output()
  cycleLengthDate = new EventEmitter<string>();

  @Output()
  startRunDate = new EventEmitter<IForecastDisplayPreference | null>();

  interval = 1;
  displayedTestRuns: TestRun[] = [];
  displayedForecastedStates: ForecastedState[] = [];
  dosArray: number[] = [];
  selectedDate?: Date;
  lastDate?: IForecastDisplayPreference;
  subs?: Subscription;
  paramConfig: any;

  constructor(
    private forecastDisplayPreferencesService: ForecastDisplayPreferencesService,
    private forecastDataPeriodsService: ForecastDataPeriodsService,
    private paramConfigService: ParamConfigService
  ) {
    this.paramConfig = this.paramConfigService.getParamsConfig();
  }

  ngOnChanges(): void {
    this.displayedTestRuns = this.forecastResults?.testRuns ?? [];

    const lastDayOnStreamInTestRuns = this.displayedTestRuns.map((x) => x.time).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    this.dosArray = this.forecastResults?.forecastedStates.map((forecast) => forecast.dos) ?? [];
    this.displayedForecastedStates = this.forecastResults?.forecastedStates.filter((s) => s.dos >= lastDayOnStreamInTestRuns) ?? [];

    this.subs = this.forecastDisplayPreferencesService.get().subscribe((forecastDisplayPreference: IForecastDisplayPreference) => {
      this.lastDate = forecastDisplayPreference;
      this.selectedDate = new Date(forecastDisplayPreference.start_of_run);
      this.startRunDate.emit( forecastDisplayPreference );
      this.addDateToForecast(this.convertDaysToDate());
    });
    this.paramConfig = this.paramConfigService.getParamsConfig();
  }

  getDpDate(date: Date) {
    this.selectedDate = date;
    this.addDateToForecast(this.convertDaysToDate());
    this.forecastDisplayPreferencesService.send({ start_of_run: new Date(moment(date).format('YYYY-MM-DD')) }).subscribe();
  }

  addDateToForecast(dateOfStream: string[]) {
    const lastDate = dateOfStream.length - 1;
    this.forecastResults?.forecastedStates.forEach((forecastState, index) => (forecastState.date = dateOfStream[index]));
    this.cycleLengthDate.emit(dateOfStream[lastDate]);
  }

  addPeriodToForecast(dos: number) {
    return this.findIntervalIndex(this.forecastDataPeriodsService.getPeriodMarkers().graphAreaMark, dos);
  }

  private findIntervalIndex(arr: { xAxis: number }[][], dos: number): number | string {
    for (let i = 0; i < arr.length; i++) {
      const interval = arr[i];
      if (dos >= interval[0]?.xAxis && dos <= interval[1]?.xAxis) {
        return i + 1;
      }
    }
    return 'Catalyst ChangeOut';
  }

  convertDaysToDate(): string[] {
    const tab: string[] = [];
    this.dosArray.forEach((dos) => {
      const dateBeforeConv = this.selectedDate ? moment(this.selectedDate).add(dos, 'd') : null;
      if (dateBeforeConv) {
        tab.push(dateBeforeConv.format('DD MMMM yyyy'));
      }
    });
    return tab;
  }

  ngOnDestroy() {
    this.subs?.unsubscribe();
  }
}
