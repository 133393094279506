
<app-ui-accordion [uniqId]="uniqId">
    <div id="title" title>Catalyst parameters</div>
    <div body  class="catalyst-container">
        <div class="row align-items-end">
            <div class="col-4"></div>
            <div class="col header">{{catalystParamConfig?.conv?.name}}</div>
            <div class="col header">{{catalystParamConfig?.hds?.name}}</div>
            <div class="col header">{{catalystParamConfig?.hdn?.name}}</div>
        </div>
        <div class="row">
            <div class="col-4 row-header">Initial Activity</div>
            <div class="col text-center"><input type="text" class="form-control" formControlName="actConv"></div>
            <div class="col text-center"><input type="text" class="form-control" formControlName="actHds"></div>
            <div class="col text-center"><input type="text" class="form-control" formControlName="actHdn"></div>
        </div>
        <div class="row">
            <div class="col-4 row-header">Coke Deactivation Factor</div>
            <div class="col text-center"><input type="text" class="form-control" formControlName="abscConv"></div>
            <div class="col text-center"><input type="text" class="form-control" formControlName="abscHds"></div>
            <div class="col text-center"><input type="text" class="form-control" formControlName="abscHdn"></div>
        </div>
        <div class="row">
            <div class="col-4 row-header">Metal Deactivation Factor</div>
            <div class="col text-center"><input type="text" class="form-control" formControlName="absmConv"></div>
            <div class="col text-center"><input type="text" class="form-control" formControlName="absmHds"></div>
            <div class="col text-center"><input type="text" class="form-control" formControlName="absmHdn"></div>
        </div>
    </div>

</app-ui-accordion>

