import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FlowersData } from 'src/app/types/flowers-data';
import { ForecastParameters } from '../../types/forecast';
import { FlowerService } from '../services/flower.service';
import { ConfirmationModalComponent } from '../../shared/components/modal/confirmation-modal/confirmation-modal.component';
import { take } from 'rxjs';
import { ParamConfigService } from 'src/app/shared/services/site-config/param-config.service';

@Component({
  selector: 'app-flowers-data-modal',
  templateUrl: './flowers-data-modal.component.html',
  styleUrls: ['./flowers-data-modal.component.scss'],
})
export class FlowersDataModalComponent {
  flowersData: FlowersData[] = [];
  formattedData: ForecastParameters[] = [];
  avgFlowersData: ForecastParameters[] = [];
  paramConfig: any;

  constructor(
    public dialogRef: MatDialogRef<FlowersDataModalComponent>,
    private matDialog: MatDialog,
    private flowerService: FlowerService,
    @Inject(MAT_DIALOG_DATA) public data: FlowersData[],
    private paramConfigService: ParamConfigService
    ) {    
    this.paramConfig = this.paramConfigService.getParamsConfig();    
    this.flowersData = data;
    this.formattedData = this.flowerService.formatFlowerData(this.flowersData);
  }

  load() {
    const dialogRef = this.matDialog.open(ConfirmationModalComponent, {
      panelClass: 'dialog-generic-container',
      data: {
        message: 'Flowers data will erase your previous input',
      },
      width: '375px',
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.dialogRef.close(this.avgFlowersData);
        }
      });
  }

  getPeriodChanged(forecastParameters: ForecastParameters[]) {
    this.avgFlowersData = forecastParameters;
  }
}
